
.app-actions {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    background-color: var(--bg-color);
    z-index: 1998;
}