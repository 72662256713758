
.app-options {
    position: absolute;
    right: 0;
    top: 0;
    width: var(100% - 200px);
    display: flex;
    justify-content: end;
    z-index: 998;
    padding: 1rem;
}