
.view-remove-component {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: #fff;
    background-color: #f00;
    border-radius: 0 0 0 0.5rem;
    z-index: 1;
}