
body {
    font-family: Montserrat,Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif;
    background-color: #334155;
}

body.console {
    background-color: #f8f9fa;
}

.navbar {
    z-index: 1000;
}

.navbar-nav {
    font-size: 16px;
}

.nav-item {
    margin-right: 10px;
    margin-left: 10px;
}

.nav-link span {
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.5s;
}

.nav-link.active span {
    border-bottom-color: #000;
}

body .btn-primary {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.btn-primary:active, .btn-primary.active {
    background-color: #f77a00;
    border-color: #f77a00;
}

/* Headings */

h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    padding-top: 20px;
}

/* Home */

.home-slider {
    height: 70vh;
    background: no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    color: #334155;
}

.home-slider h1 {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.home-slider p {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 20px;
}

.home-partners {
    background-color: #d1dae7;
}

/* Feature */

.feature {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 25rem;
}

.feature img {
    display: block;
    margin: 0 auto;
    height: 15rem;
    object-fit: cover;
    padding: 3rem;
}

.feature p {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    height: 20rem;
}

.feature .more {
    display: block;
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.5s;
}

.feature .more:hover {
    color: #000;
}

.feature .coming-soon {
    display: block;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
}


/* Body */

.web-body {
    padding-top: 68px;
    margin-bottom: 0;
}

/* Partner */

.partner img {
    max-width: 20rem;
    max-height: 5rem;
    object-fit: cover;
}

/* Footer */

header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

footer {
    background-color: #334155;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    font-size: 1.3rem;
}

footer p {
    color: #fff;
}

/* Product */

.sub {
    color: #a2a6b0;
    font-size: 1.2rem;
}

.desc {
    color: #6b7280;
    font-size: 1.2rem;
}

/* About */

p {
    font-size: 1.2rem;
    color: #6b7280;
}

img.cover {
    object-fit: cover;
}

.float-img {
    max-width: 20rem;
}